<template>
  <div class="top">
    <div class="topTop">
      客服排队中提醒
      <el-switch @change="welcomeChange" style="display: block" v-model="waitTips"> </el-switch>
    </div>
    <div class="topBot">
      转人工后，所有客服都不在线或没有接待余量（最大接待客户数-正在接待客户数）时，会发送这个回复
    </div>
  </div>
  <div class="mid">
    <div class="yuan"></div>
    回复内容(若都勾选则自动回复文字消息+常见问题)
  </div>
  <div class="footer">
    <Phone type="waiting" :obj="welcome"></Phone>
    <div class="footerRight">
      <div class="sendTxtMsg">
        <el-checkbox :disabled="!waitTips" v-model="welcome.sendTxtMsg.showflag"
          >发送文字消息</el-checkbox
        >
        <div class="content">
          <div class="leftTitle">回复内容:</div>
          <div class="rightContent">
            <div class="txt" v-if="!updateFlag && welcome.sendTxtMsg.content">
              {{ welcome.sendTxtMsg.content }}
            </div>
            <ChatInputBox
              v-if="updateFlag"
              :chatData="chatData"
              @getChatData="getChatData"
            ></ChatInputBox>
            <!-- <div class="updateTxt" >
              <el-input
                ref="sendMsgUpdate"
                :spellcheck="false"
                type="textarea"
                placeholder="请输入内容"
                v-model="updateTxt"
                maxlength="500"
                show-word-limit
                @blur="chatContentChange"
              >
              </el-input>
              <div class="expression">
                <el-popover
                  class="addSome"
                  v-model:visible="expressionFlag"
                  width="300px"
                  trigger="click"
                >
                  <div>
                    <div class="classStyle">
                      <span
                        @click="expressionListActive = index"
                        v-for="(item, index) in expressionList"
                        :key="index"
                        :style="{
                          background: expressionListActive == index ? '#e7e7e7' : ''
                        }"
                        >{{ item.name }}</span
                      >
                    </div>
                    <div
                      class="emojisList"
                      v-for="(item, index) in expressionList"
                      :key="index"
                      :style="{
                        display: expressionListActive == index ? 'block' : 'none'
                      }"
                    >
                      <div>
                        <span
                          @click="insertEmojis(item1)"
                          v-for="(item1, index1) in item.list"
                          :key="index1"
                          >{{ item1 }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <template #reference>
                    <i class="iconfont icon-biaoqing"></i>
                  </template>
                </el-popover>
              </div>
            </div> -->
            <div v-if="!updateFlag" class="update" @click="updateSendMsg">编辑</div>
            <div v-else class="updateRes">
              <div @click="updateSuccess" class="custBtnPri">保存</div>
              <div @click="updateFlag = false" class="custBtn">取消</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sendCommonProblem">
        <el-checkbox :disabled="!waitTips" v-model="welcome.sendCommonProblem.showflag"
          >发送常见问题</el-checkbox
        >
        <div class="problemList">
          <div class="problemListLeft">问题列表:</div>
          <div class="problemListRight">
            <template v-for="(item, index) in welcome.sendCommonProblem.list" :key="index">
              <div>{{ item.asking }}</div>
            </template>
            <div class="txtTips">
              回复内容等详细设置请前往常见问题页设置设置常见问题<span
                @click="$router.push('/autoReply/serviceReply/faq')"
                style="margin-left: 10px"
                class="textBtn"
                >设置常见问题</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="sendGoods">
        <el-checkbox :disabled="!waitTips" v-model="welcome.waitingTips.showflag"
          >发送当前排队进展系统提示</el-checkbox
        >
        <div class="txtTips">
          <p>当客户发消息时，如果进入人工客服排队列表，会提示前面还有多少位，请等待或留言；</p>
          <p>客服下班后不发送</p>
        </div>
      </div>
      <div class="wxCode">
        <el-checkbox :disabled="!waitTips" v-model="welcome.manCustom.showflag"
          >发送客服接入提示</el-checkbox
        >
        <div class="txtTips">当人工客服接入时，会提示已由在线人工客服接入</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, nextTick, inject } from 'vue'
import { post } from '../../../util/axios'
import { api } from '../../../util/api'
import Phone from '@/components/phone.vue'
import { ElMessage } from 'element-plus'
import ChatInputBox from '@/components/chatInputBox'
export default {
  components: {
    Phone,
    ChatInputBox
  },
  setup() {
    const waitTips = ref(true)
    let replyInfo = inject('replyInfo')
    waitTips.value = replyInfo.value.service_queue_reminder ? true : false
    const commonProblemList = inject('commonProblemList')
    const welcome = reactive({
      sendTxtMsg: {
        showflag: replyInfo.value.service_queue_reminder_data.text_open == '1' ? true : false,
        content: replyInfo.value.service_queue_reminder_data.text_value,
        updateFlag: false
      },
      sendCommonProblem: {
        showflag: replyInfo.value.service_queue_reminder_data.common_problem == '1' ? true : false,
        list: commonProblemList.value
      },
      waitingTips: {
        showflag: replyInfo.value.service_queue_reminder_data.progress_notice == '1' ? true : false
      },
      manCustom: {
        showflag: replyInfo.value.service_queue_reminder_data.client_notice == '1' ? true : false
      }
    })
    let cloneList = reactive({
      a: JSON.parse(JSON.stringify(welcome.sendCommonProblem.list))
    })
    watch(
      welcome,
      (val) => {
        if (val.sendCommonProblem.list.toString() == cloneList.a.toString()) {
          post(api.setAutomaticReply, {
            service_queue_reminder_json: {
              text_open: val.sendTxtMsg.showflag ? 1 : 0,
              text_value: val.sendTxtMsg.content,
              common_problem: val.sendCommonProblem.showflag ? 1 : 0,
              progress_notice: val.waitingTips.showflag ? 1 : 0,
              client_notice: val.manCustom.showflag ? 1 : 0
            }
          }).then((res) => {
            if (res.code == 0) {
              ElMessage.success('修改成功')
            } else {
              ElMessage.error(`${res.msg}`)
            }
          })
        }
        cloneList.a = JSON.parse(JSON.stringify(val.sendCommonProblem.list))
      },
      {
        deep: true
      }
    )
    let updateFlag = ref(false)
    const chatData = ref('')
    const getChatData = (val) => {
      chatData.value = val
    }
    // 点击修改欢迎语
    const updateSendMsg = () => {
      chatData.value = welcome.sendTxtMsg.content
      updateFlag.value = true
    }
    // 完成修改
    const updateSuccess = () => {
      welcome.sendTxtMsg.content = chatData.value
      if (!welcome.sendTxtMsg.content) {
        welcome.sendTxtMsg.showflag = false
      }
      updateFlag.value = false
    }
    const welcomeChange = (val) => {
      replyInfo.value.service_queue_reminder = val ? 1 : 0
      post(api.setAutomaticReply, {
        service_queue_reminder: val ? 1 : 0
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    return {
      waitTips,
      welcome,
      chatData,
      getChatData,
      updateSuccess,
      updateSendMsg,
      updateFlag,
      welcomeChange
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  padding: 20px;
  background-color: #f7f8fa;
  .topTop {
    display: flex;
    align-items: center;
    font-size: 15px;
    .el-switch {
      margin-left: 10px;
    }
  }
  .topBot {
    margin-top: 16px;
    font-size: 14px;
    color: #999;
  }
}
.mid {
  margin-top: 10px;
  padding: 15px 20px;
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  .yuan {
    margin-right: 5px;
    width: 6px;
    height: 6px;
    background: #409eff;
    border-radius: 50%;
  }
}
.footer {
  margin-top: 23px;
  margin-bottom: 80px;
  display: flex;
  align-items: flex-start;
  .footerRight {
    margin-left: 34px;
    width: calc(100% - 409px);
    :deep(.el-checkbox) {
      :depp(.el-checkbox__label) {
        color: #333 !important;
      }
    }
    .content {
      margin-top: 17px;
      display: flex;
      .leftTitle {
        margin-left: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        line-height: 1.5;
      }
      .txt {
        line-height: 1.5;
        margin-bottom: 14px;
        white-space: pre-wrap;
      }
      .update {
        color: #409eff;
        user-select: none;
        line-height: 1.5;
        cursor: pointer;
      }
      .updateRes {
        display: flex;
        margin-top: 15px;
        & > div {
          margin-right: 10px;
        }
      }
    }
  }
}

.sendCommonProblem {
  margin-top: 20px;
  .problemList {
    display: flex;
    margin-left: 24px;
    margin-top: 17px;
    .problemListRight {
      margin-left: 10px;
      & > div {
        margin-bottom: 12px;
      }
    }
  }
}
.sendGoods {
  .txtTips {
    margin-top: 10px;
    line-height: 1.5;
    margin-left: 24px;
    p {
      font-size: 12px !important;
    }
  }
}
.wxCode {
  margin-top: 20px;
  .txtTips {
    margin-top: 10px;
    line-height: 1.5;
    margin-left: 24px;
  }
}
</style>
